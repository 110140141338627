<template>

        <Menu :slug="slug" :active="active"/>

</template>

<script>
import { ref } from '@vue/reactivity'
import Menu from './tours/update/TabMenu.vue'

export default {
    components:{
        Menu
    },
    setup() {
        const slug = ref('slug my');
        const active = ref(1);

        return {
            slug,
            active
        }
    }
}
</script>